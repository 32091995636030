import _extends from "@babel/runtime/helpers/extends";
import { useCallback, useRef, useState, Fragment } from "react";
import { useRect } from "@reach/rect";
import { useBlocker, useWaitElementInViewport, Portal, CloseIcon, BlockerButtonsInlineStyles, BlockerHeroInlineStyles, BlockerTextContainer, BlockerHeroAudioWaveFromText, BlockerHeroVideoSvgPlayButton } from "../../..";
const BlockerHeroContainer = () => {
  const blocker = useBlocker();
  const {
    headerDesign: {
      fontSize,
      fontColor
    },
    texts: {
      blockerHeadline
    },
    blocker: {
      presetId,
      name,
      visualHeroButtonText,
      visualContentType,
      visualThumbnail
    },
    paintMode,
    i18n: {
      close
    }
  } = blocker;
  const ref = useRef();
  const [isBtnHover, setBtnHover] = useState(false);
  const [isHover, setIsHover] = useState(false);
  const [isDefaultBlockerVisible, setIsDefaultBlockerVisible] = useState(false);
  const rect = useRect(ref, {
    observe: isDefaultBlockerVisible
  });
  const {
    hide
  } = visualThumbnail;
  const title = visualThumbnail.title || name;
  const audioWaveText = `${visualThumbnail.title}ThiS iS jUst ANother TEXT TO reACh minIMum length` || blockerHeadline.replace(/{{name}}/gi, name);

  // Lazy load background image
  const isBackgroundVisible = paintMode === "instantInViewport" || useWaitElementInViewport(ref);

  // Automatically close on outside click
  const overlayRef = useRef();
  const defaultBlockerContainerRef = useRef();
  const handleOutsideClick = useCallback(e => {
    if (isDefaultBlockerVisible && [overlayRef.current, defaultBlockerContainerRef.current].indexOf(e.target) > -1) {
      setIsDefaultBlockerVisible(false);
    }
  }, [isDefaultBlockerVisible, overlayRef.current, defaultBlockerContainerRef.current]);
  const hideTitle = hide?.indexOf("title") > -1;
  const showPlayButton = ["video-player", "audio-player"].indexOf(visualContentType) > -1;
  const btnAttributes = {
    onMouseEnter: () => setBtnHover(true),
    onMouseLeave: () => setBtnHover(false),
    onClick: e => {
      if (e) {
        e.preventDefault();
        e.stopPropagation();
      }
      setIsDefaultBlockerVisible(true);
    }
  };
  return h("div", _extends({}, BlockerHeroInlineStyles.aspectRatioWrapper(blocker), {
    ref: ref
  }), isBackgroundVisible && h("div", BlockerHeroInlineStyles.aspectRatioBackgroundWrapper(blocker), h("div", BlockerHeroInlineStyles.aspectRatioBackground(blocker))), h("div", _extends({}, BlockerHeroInlineStyles.aspectRatioWholeClickable(blocker), {
    onMouseEnter: () => setIsHover(true),
    onMouseLeave: () => setIsHover(false),
    onClick: () => setIsDefaultBlockerVisible(true)
  })), h("div", BlockerHeroInlineStyles.aspectRatioContainer(blocker), !hideTitle && h("div", _extends({}, BlockerHeroInlineStyles.title(blocker), {
    title: title
  }), title), visualContentType === "audio-player" && h(BlockerHeroAudioWaveFromText, {
    text: audioWaveText
  }), visualHeroButtonText && ["audio-player", "video-player"].indexOf(visualContentType) === -1 ? h("div", _extends({}, BlockerButtonsInlineStyles.hero(blocker, isBtnHover, false), btnAttributes), "\xA0\xA0", visualHeroButtonText, "\xA0\xA0") : showPlayButton && (presetId === "youtube" && visualContentType === "video-player" ? h(BlockerHeroVideoSvgPlayButton, {
    svgProps: btnAttributes,
    fill: isBtnHover || isHover ? "#ff0808" : `rgb(0 0 0 / 70%)`
  }) : h("div", _extends({}, BlockerHeroInlineStyles.playButtonWrapper(blocker, isBtnHover), btnAttributes), h("div", BlockerHeroInlineStyles.playButton(blocker, isBtnHover)))), isDefaultBlockerVisible && rect && h(Portal, {
    renderInContainer: document.body
  }, h(Fragment, null, h("div", _extends({}, BlockerHeroInlineStyles.defaultBlockerOverlay(blocker, isDefaultBlockerVisible), {
    ref: overlayRef,
    onClick: handleOutsideClick
  })), h("div", _extends({}, BlockerHeroInlineStyles.defaultBlockerContainer(blocker, rect), {
    ref: defaultBlockerContainerRef,
    onClick: handleOutsideClick
    // eslint-disable-next-line react/no-unknown-property
    ,
    "interaction-player-uqid": isDefaultBlockerVisible ? "blocker" : undefined
  }), h(BlockerTextContainer, {
    closeIcon: h(CloseIcon, {
      tooltipText: close,
      width: fontSize,
      color: fontColor,
      thickness: 2,
      onClick: () => setIsDefaultBlockerVisible(false)
    })
  }))))));
};
export { BlockerHeroContainer };